<template>
  <v-row>
    <v-col cols="12">
      <div
        v-if="loaded"
        class="contact-us"
      >
        <template v-if="!success">
          <h1 class="contact-us-title text-uppercase text-center">
            Contact Us
          </h1>
          <v-form
            ref="form"
            v-model="valid"
            class="contact-us-form"
            @submit.prevent="submit"
          >
            <v-row class="py-0">
              <v-col class="col-12 col-sm-5 py-0">
                <v-text-field
                  v-model="item.name"
                  name="name"
                  placeholder="Name"
                  height="var(--input-height)"
                  solo
                  autofocus
                  required
                  :rules="rules.name"
                  :validate-on-blur="!showErrorMessage"
                />
              </v-col>
              <v-col class="col-12 col-sm-7 py-0">
                <v-text-field
                  v-model="item.email"
                  name="email"
                  placeholder="Email address"
                  height="var(--input-height)"
                  solo
                  required
                  :rules="rules.email"
                  :validate-on-blur="!showErrorMessage"
                />
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col cols="12 py-0">
                <v-textarea
                  v-model="item.message"
                  height="146"
                  solo
                  name="message"
                  placeholder="Message"
                  no-resize
                  required
                  :rules="rules.message"
                  :validate-on-blur="!showErrorMessage"
                />
              </v-col>
            </v-row>
            <v-row class="py-0 contact-us-bottom">
              <v-col class="col-auto py-0">
                <div class="contact-us-privacy">
                  <div>
                    <img
                      src="@/assets/img/privacy.svg"
                      alt=""
                    >
                  </div>
                  <div>
                    <router-link
                      :to="{ name: 'privacyPolicy' }"
                      class="h5"
                      data-cy="privacy-policy"
                    >
                      Privacy Policy
                    </router-link>
                  </div>
                </div>
              </v-col>
              <v-col class="col-auto py-0">
                <v-btn
                  x-large
                  color="primary"
                  type="submit"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>

        <template v-else>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <img
              src="@/assets/img/scheduled-success.svg"
              alt="Success!"
            >
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <h1 class="h1">
              Success!
            </h1>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              :to="{ name: 'signIn' }"
              x-large
              color="primary"
            >
              Home
            </v-btn>
          </v-col>
        </template>
      </div>
      <overlay v-else />
    </v-col>
  </v-row>
</template>

<script>
import Overlay from '@/components/Overlay'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'ContactUs',
  components: { Overlay },
  data() {
    return {
      item: {
        name: null,
        email: null,
        message: null
      },
      valid: true,
      showErrorMessage: false,
      loaded: true,
      success: false,
      rules: {
        name: [
          v => !!v || 'Name is required',
          v => (v ? v.length <= 50 || 'Max 50 characters' : true)
        ],
        email: [v => !!v || 'Email is required', v => emailValidation(v)],
        message: [
          v => !!v || 'Message is required',
          v => (v ? v.length >= 20 || 'Min 20 characters' : true)
        ]
      }
    }
  },
  beforeDestroy() {
    this.resetItem()
  },
  methods: {
    async submit() {
      this.showErrorMessage = true

      if (this.$refs.form.validate()) {
        this.loaded = false

        await this.$firebase
          .firestore()
          .collection('contacts')
          .doc()
          .set({
            ...this.item,
            createdAt: this.$firebase.firestore.Timestamp.now()
          })
          .then(() => {
            this.success = true
            this.resetItem()
          })
          .catch(() => {
            this.$notify({
              type: 'err',
              text: 'Send error'
            })
          })

        this.loaded = true
      }
    },
    resetItem() {
      this.item = {
        name: null,
        email: null,
        message: null
      }
    }
  },
  head: {
    title: {
      inner: 'Contact Us'
    },
    meta: [
      {
        name: 'description',
        content: 'Contact Us',
        id: 'desc'
      }
    ]
  }
}
</script>

<style lang="scss">
.contact-us {
  max-width: 754px;
  margin: 0 auto;
  &-bottom {
    justify-content: space-between;
    @media (max-width: 374px) {
      justify-content: center;
      .col {
        width: 100%;
        flex-basis: 100%;
        text-align: center;
      }
      .v-btn {
        margin-top: 20px;
      }
    }
  }
  &-title {
    margin-bottom: 40px;
    font-size: 28px;
    color: var(--v-primary-base);
    @media (max-width: 599px) {
      margin-bottom: 26px;
    }
  }
  .h1 {
    margin-top: 20px;
    font-size: 36px;
    color: var(--v-primary-base);
  }
  .v-input__slot {
    margin-bottom: 1px !important;
  }
  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    margin-top: 0;
    padding: 0;
  }
  &-privacy {
    display: flex;
    align-content: center;
    img {
      width: 16px;
    }
    a {
      margin-left: 12px;
      color: var(--v-neutralMedium-base);
      font-weight: 400;
      text-decoration: none;
    }
  }
}
</style>
